<template>
    <div class="text-center">
        <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
            <template v-slot:activator="{ on, attrs }">
                <v-btn absolute fab color="primary" class="elevation-24" style="top: 4px; right: 10px" v-bind="attrs" v-on="on">
                    <v-icon>menu</v-icon>
                </v-btn>
            </template>

            <v-card>
                <v-list>
                    <v-list-item>
                        <v-list-item-avatar> </v-list-item-avatar>
                        <v-list-item-content> </v-list-item-content>

                        <v-list-item-action>
                            <v-btn class="gray--text" icon @click="menu = false">
                                <v-icon>close</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="title">{{ $t("Olá") }}, {{ this.$store.state.user.name }}!!!</v-list-item-title>
                            <v-list-item-subtitle>{{ this.$store.state.login.email }}</v-list-item-subtitle>
                            <Version class="mt-6" />
                        </v-list-item-content>

                        <v-list-item-action>
                            <router-link to="/" tag="v-btn">
                                <v-btn class="primary--text elevation-12 mt-1" icon @click="menu = false">
                                    <v-icon>home</v-icon>
                                </v-btn>
                            </router-link>
                            <router-link to="/my-information" tag="v-btn">
                                <v-btn class="primary--text elevation-12 mt-1" icon @click="menu = false">
                                    <v-icon>settings</v-icon>
                                </v-btn>
                            </router-link>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>

                <div v-if="$store.getters.menuMode == 'USER' && verifyRole('USER')">
                    <v-divider />
                    <v-list dense v-for="(item, i) in getMenus('USER')" :key="i">
                        <router-link :to="{ path: item.path }" tag="v-list-item">
                            <v-list-item @click="menu = false">
                                <v-list-item-icon v-if="item.meta.menu.icon">
                                    <v-icon color="primary">{{ item.meta.menu.icon }}</v-icon>
                                </v-list-item-icon>

                                <v-list-item-title v-if="item.meta.menu.title">{{ item.meta.menu.title }}</v-list-item-title>
                            </v-list-item>
                        </router-link>
                    </v-list>
                </div>

                <div v-if="$store.getters.menuMode == 'USER' && verifyRole('EXPERT')">
                    <v-divider />
                    <v-list dense v-for="(item, i) in getMenus('EXPERT')" :key="i">
                        <router-link :to="{ path: item.path }" tag="v-list-item">
                            <v-list-item @click="menu = false">
                                <v-list-item-icon v-if="item.meta.menu.icon">
                                    <v-icon color="primary">{{ item.meta.menu.icon }}</v-icon>
                                </v-list-item-icon>

                                <v-list-item-title v-if="item.meta.menu.title">{{ item.meta.menu.title }}</v-list-item-title>
                            </v-list-item>
                        </router-link>
                    </v-list>
                </div>

                <div v-if="$store.getters.menuMode == 'USER' && verifyRole('PRE_EXPERT')">
                    <v-divider />
                    <v-list dense v-for="(item, i) in getMenus('PRE_EXPERT')" :key="i">
                        <router-link :to="{ path: item.path }" tag="v-list-item">
                            <v-list-item @click="menu = false">
                                <v-list-item-icon v-if="item.meta.menu.icon">
                                    <v-icon color="primary">{{ item.meta.menu.icon }}</v-icon>
                                </v-list-item-icon>

                                <v-list-item-title v-if="item.meta.menu.title">{{ item.meta.menu.title }}</v-list-item-title>
                            </v-list-item>
                        </router-link>
                    </v-list>
                </div>

                <div v-if="$store.getters.menuMode == 'USER' && verifyRole('COLLECTOR')">
                    <v-divider />
                    <v-subheader>{{ $t("Transportador") }}</v-subheader>
                    <v-list dense v-for="(item, i) in getMenus('COLLECTOR')" :key="i">
                        <router-link :to="{ path: item.path }" tag="v-list-item">
                            <v-list-item @click="menu = false">
                                <v-list-item-icon v-if="item.meta.menu.icon">
                                    <v-icon color="primary">{{ item.meta.menu.icon }}</v-icon>
                                </v-list-item-icon>

                                <v-list-item-title v-if="item.meta.menu.title">{{ item.meta.menu.title }}</v-list-item-title>
                            </v-list-item>
                        </router-link>
                    </v-list>
                </div>

                <div v-if="$store.getters.menuMode == 'USER' && verifyRole('PARTNER')">
                    <v-divider />
                    <v-subheader>{{ $t("Parceiro") }}</v-subheader>
                    <v-list dense v-for="(item, i) in getMenus('PARTNER')" :key="i">
                        <router-link :to="{ path: item.path }" tag="v-list-item">
                            <v-list-item @click="menu = false">
                                <v-list-item-icon v-if="item.meta.menu.icon">
                                    <v-icon color="primary">{{ item.meta.menu.icon }}</v-icon>
                                </v-list-item-icon>

                                <v-list-item-title v-if="item.meta.menu.title">{{ item.meta.menu.title }}</v-list-item-title>
                            </v-list-item>
                        </router-link>
                    </v-list>
                </div>

                <div v-if="$store.getters.menuMode == 'OPERATION' && verifyRole('OPERATION')">
                    <v-divider />
                    <v-subheader>{{ $t("Operador") }}</v-subheader>
                    <v-list dense v-for="(item, i) in getMenus('OPERATION')" :key="i" class="pa-1">
                        <router-link :to="{ path: item.path }" tag="v-list-item">
                            <v-list-item @click="menu = false">
                                <v-list-item-icon v-if="item.meta.menu.icon">
                                    <v-icon color="primary">{{ item.meta.menu.icon }}</v-icon>
                                </v-list-item-icon>

                                <v-list-item-title v-if="item.meta.menu.title">{{ item.meta.menu.title }}</v-list-item-title>
                            </v-list-item>
                        </router-link>
                    </v-list>
                </div>

                <div v-if="$store.getters.menuMode == 'ADMIN' && verifyRole('ADMIN')">
                    <v-divider />
                    <v-subheader>{{ $t("Administrador") }}</v-subheader>
                    <v-list dense v-for="(item, i) in getMenus('ADMIN')" :key="i" class="pa-1">
                        <router-link :to="{ path: item.path }" tag="v-list-item">
                            <v-list-item @click="menu = false">
                                <v-list-item-icon v-if="item.meta.menu.icon">
                                    <v-icon color="primary">{{ item.meta.menu.icon }}</v-icon>
                                </v-list-item-icon>

                                <v-list-item-title v-if="item.meta.menu.title">{{ item.meta.menu.title }}</v-list-item-title>
                            </v-list-item>
                        </router-link>
                    </v-list>
                </div>

                <v-divider />

                <v-card-actions>
                    <v-btn-toggle v-if="verifyRole('ADMIN') || verifyRole('OPERATION')" variant="elevated" color="primary" v-model="menuMode" mandatory divided>
                        <v-btn small value="USER">USER</v-btn>
                        <v-btn small value="OPERATION" v-if="verifyRole('OPERATION')">OPER</v-btn>
                        <v-btn small value="ADMIN" v-if="verifyRole('ADMIN')">ADMIN</v-btn>
                    </v-btn-toggle>
                    <v-spacer></v-spacer>
                    <router-link :to="{ path: '/' }" v-on:click.native="$store.commit('CLEAR_LOGIN') & $emit('logout')">
                        <v-btn dark color="primary" @click="menu = false"> <v-icon left dark>exit_to_app</v-icon>Logout </v-btn>
                    </router-link>
                </v-card-actions>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
import Version from "@/components/core/Version";
import { mapGetters } from "vuex";

export default {
    name: "MenuLogged",

    data() {
        return {
            menu: false,
        };
    },

    components: { Version },

    computed: {
        ...mapGetters(["verifyRole", "verifyScreem"]),
        getMenus() {
            return (role) =>
                this.$router.options.routes.filter(
                    (route) =>
                        route.meta &&
                        route.meta.role == role &&
                        route.meta.menu &&
                        !route.meta.menu.level &&
                        (route.meta.menu.icon || route.meta.menu.title) &&
                        (!route.meta.menu.needsPermission || this.verifyScreem(route.meta.menu.needsPermission))
                );
        },
        menuMode: {
            get() {
                return this.$store.getters.menuMode
            },
            set(value) {
                this.$store.commit('CHANGE_MENU_MODE', value)
            }
        }
    },
};
</script>