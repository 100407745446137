import Home from '@/pages/Home';
import Login from '@/pages/Login';
import Daily from '@/pages/Daily';

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/helpers/store'

import VueI18n from 'vue-i18n'
import dictionary from '@/plugins/i18n'
const i18n = new VueI18n(dictionary)

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: Home,
        meta: {}
    },
    {
        path: '/privacy',
        component: () => import('@/pages/Privacy'),
        meta: {}
    },
    {
        path: "/login",
        component: Login,
        meta: {}
    },
    {
        path: '*', component: Home
    },
    {
        path: '/my-information',
        component: () => import('@/pages/MyInformation'),
        meta: {
            role: 'USER',
        }
    },
    {
        path: '/expert-register',
        component: () => import('@/pages/ExpertRegister'),
        meta: {}
    },
    {
        path: '/recovery/:token',
        component: () => import('@/pages/Recovery'),
        meta: {}
    },
    {
        path: "/recovery",
        component: () => import('@/pages/Recovery'),
        meta: {}
    },
    {
        name: 'confirmregister',
        path: '/confirmregister/:token',
        component: () => import('@/pages/ConfirmRegister'),
        meta: {}
    },
    {
        path: '/pendingExpert',
        component: () => import('@/pages/PendingExpert'),
        meta: {
            role: 'PRE_EXPERT',
            menu: {
                title: i18n.t('Cadastro'),
                icon: 'contact_mail',
            },
        }
    },
    {
        path: '/daily',
        component: Daily,
        meta: {
            role: 'EXPERT',
            menu: {
                title: i18n.t('Atividades diárias'),
                icon: 'push_pin',
            },
        }
    },
    {
        path: '/collect-check',
        component: () => import('@/pages/CollectCheck'),
        meta: {
            role: 'COLLECTOR',
            menu: {
                title: i18n.t('Coleta em loja'),
                icon: 'checklist_rtl',
            },
        }
    },
    {
        path: '/dashboard-operation',
        component: () => import('@/pages/DashboardOperation'),
        meta: {
            role: 'PARTNER',
            redirectToWhenNotPermit: 'Login',
            menu: {
                title: i18n.t('Dashboard - Operações R2PP'),
                icon: 'dashboard',
                needsPermission: 'EXPERT_OPERATION',
            },
        }
    },
    {
        path: '/collect-partner',
        component: () => import('@/pages/DashboardMilkrun'),
        meta: {
            role: 'PARTNER',
            redirectToWhenNotPermit: 'Login',
            menu: {
                title: i18n.t('Dashboard - Milk Run'),
                icon: 'dashboard',
                needsPermission: 'EXPERT_MILKRUN',
            },
        }
    },
    {
        path: '/receive-check',
        component: () => import('@/pages/ReceiveCheck'),
        meta: {
            role: 'OPERATION',
            menu: {
                title: i18n.t('Recebimento pacotes R2PP'),
                icon: 'checklist_rtl',
            },
        }
    },
    {
        path: '/return-pack',
        component: () => import('@/pages/ReturnPack'),
        meta: {
            role: 'OPERATION',
            menu: {
                title: i18n.t('Devolução'),
                icon: 'keyboard_return',
            },
        }
    },
    {
        path: '/packages',
        component: () => import('@/pages/Package'),
        meta: {
            role: 'OPERATION',
            menu: {
                title: i18n.t("Trânsito de Pacotes"),
                icon: 'track_changes',
            },
        }
    },
    {
        path: '/tracking',
        component: () => import('@/pages/Tracking'),
        meta: {
            role: 'OPERATION',
            menu: {
                title: i18n.t("Rastreamento de pacote"),
                icon: 'timeline',
            },
        }
    },
    {
        path: '/partner-report',
        component: () => import('@/pages/PartnerReport'),
        meta: {
            role: 'OPERATION',
            menu: {
                title: i18n.t('Relatório de operação'),
                icon: 'summarize',
            },
        }
    },
    {
        path: '/checkin',
        component: () => import('@/pages/Checkin'),
        meta: {
            role: 'OPERATION',
            menu: {
                title: i18n.t('Check-in'),
                icon: 'push_pin',
            },
        }
    },
    {
        path: '/collect',
        component: () => import('@/pages/Collect'),
        meta: {
            role: 'OPERATION',
            menu: {
                title: i18n.t('Cadastro de Coleta'),
                icon: 'inventory',
            },
        }
    },
    {
        path: '/modal',
        component: () => import('@/pages/Modal'),
        meta: {
            role: 'OPERATION',
            menu: {
                title: i18n.t('Modal'),
                icon: 'two_wheeler',
            },
        }
    },
    {
        path: '/batteries',
        component: () => import('@/pages/Battery'),
        meta: {
            role: 'OPERATION',
            menu: {
                title: i18n.t('Baterias'),
                icon: 'battery_std',
            },
        }
    },
    {
        path: '/expert-stores',
        component: () => import('@/pages/ExpertStore'),
        meta: {
            role: 'OPERATION',
            menu: {
                title: i18n.t('Associação (Especialista - Lojas)'),
                icon: 'call_merge',
            },
        }
    },
    {
        path: '/experts',
        component: () => import('@/pages/Expert'),
        meta: {
            role: 'OPERATION',
            menu: {
                title: i18n.t('Especialistas'),
                icon: 'sports_motorsports',
            },
        }
    },
    {
        path: '/planning',
        component: () => import('@/pages/Planning'),
        meta: {
            role: 'OPERATION',
            menu: {
                title: i18n.t('Planejamento de turnos (Loja)'),
                icon: 'today',
            },
        }
    },
    {
        path: '/checklists',
        component: () => import('@/pages/Checklist'),
        meta: {
            role: 'OPERATION',
            menu: {
                title: i18n.t("Romaneios"),
                icon: 'summarize',
            },
        }
    },
    {
        path: '/ats',
        component: () => import('@/pages/AT'),
        meta: {
            role: 'OPERATION',
            menu: {
                title: i18n.t("AT's"),
                icon: 'summarize',
            },
        }
    },
    {
        path: '/operation-report',
        component: () => import('@/pages/OperationReport'),
        meta: {
            role: 'ADMIN',
            menu: {
                title: i18n.t('Relatório de operação'),
                icon: 'summarize',
            },
        }
    },
    {
        path: '/calendars',
        component: () => import('@/pages/Calendar'),
        meta: {
            role: 'ADMIN',
            menu: {
                title: i18n.t('Cadastro de turnos (Loja)'),
                icon: 'today',
            },
        }
    },
    {
        path: '/stores',
        component: () => import('@/pages/Store'),
        meta: {
            role: 'ADMIN',
            menu: {
                title: i18n.t('Lojas'),
                icon: 'store',
            },
        }
    },
    {
        path: '/clients',
        component: () => import('@/pages/Client'),
        meta: {
            role: 'ADMIN',
            menu: {
                title: i18n.t('Clientes'),
                icon: 'groups',
            },
        }
    },
    {
        path: '/rentals',
        component: () => import('@/pages/Rental'),
        meta: {
            role: 'ADMIN',
            menu: {
                title: i18n.t('Locadoras'),
                icon: 'car_rental',
            },
        }
    },
    {
        path: '/charger',
        component: () => import('@/pages/Charger'),
        meta: {
            role: 'ADMIN',
            menu: {
                title: i18n.t('Carregadores'),
                icon: 'charging_station',
            },
        }
    },
    {
        path: '/users',
        component: () => import('@/pages/User'),
        meta: {
            role: 'ADMIN',
            menu: {
                title: i18n.t('Usuários'),
                icon: 'people',
            },
        }
    },
    {
        path: '/contacts',
        component: () => import('@/pages/Contact'),
        meta: {
            role: 'ADMIN',
            menu: {
                title: i18n.t('Contatos'),
                icon: 'contacts',
            },
        }
    },
    // {
    //     path: '/notfis',
    //     component: () => import('@/pages/Notfis'),
    //     meta: {
    //         role: 'ADMIN',
    //         menu: {
    //             title: i18n.t('Notfis'),
    //             icon: 'inventory',
    //         },
    //     }
    // },
    {
        path: '/integrations',
        component: () => import('@/pages/Integration'),
        meta: {
            role: 'ADMIN',
            menu: {
                title: i18n.t('Integração NEC'),
                icon: 'cloud_sync',
            },
        }
    },
    {
        path: '/regions',
        component: () => import('@/pages/Region'),
        meta: {
            role: 'ADMIN',
            menu: {
                title: i18n.t('Região'),
                icon: 'map',
            },
        }
    },
];

const router = new VueRouter({
    routes,
    mode: 'history'
});

router.beforeEach((routeTo, routeFrom, next) => {
    store.commit("FIRST_REQUEST", true);

    if (routeTo.path == "/login") {
        store.state.routeBeforeLogin = routeFrom.path;
    }

    // Enter here if route has a role to access
    if (routeTo.meta && routeTo.meta.role) {
        try {
            // If user not logged or not contains permission, send to home page
            if (store.getters.verifyRole(routeTo.meta.role) == false) {
                return next({ path: routeTo.meta.redirectToWhenNotPermit ? routeTo.meta.redirectToWhenNotPermit : "/" });
            }
        } catch (e) {
            return next({ path: "/" });
        }
    }
    next()
})

export default router