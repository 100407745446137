<template>
    <v-form v-model="valid" ref="form" @submit.prevent="login">
        <v-text-field type="email" :label="$t('Digite o usuário')" v-model="input.email" :rules="[rules.required, rules.email]" required />
        <v-text-field :label="$t('Digite a senha')" v-model="input.password" min="8" :rules="[rules.required, rules.password]"
            :append-icon="show1 ? 'visibility' : 'visibility_off'" :type="show1 ? 'text' : 'password'" @click:append="show1 = !show1" counter required @keyup.enter="login()">
        </v-text-field>
        <v-switch v-model="input.keep" inset :label="$t('Manter logado')" />
        <v-row align="center" justify="center" align-content="space-between">
            <v-col cols="auto" class="mr-auto">
                <v-btn :loading="loading" :disabled="loading || !valid" v-on:click="login" :class="{ 'success white--text': valid }" :small="$vuetify.breakpoint.smAndDown"
                    width="45%">
                    Entrar
                </v-btn>
                <router-link :to="{ path: '/' }" tag="v-btn">
                    <v-btn color="error" class="mx-sm-1" :small="$vuetify.breakpoint.smAndDown" width="45%">Voltar</v-btn>
                </router-link>
            </v-col>
            <v-col cols="auto" class="ml-auto">

                <router-link :to="{ path: '/recovery' }" tag="v-btn">
                    <v-btn text small right class="text-body-2" style="text-transform: none" @click="goToRecovery">Esqueceu a senha?</v-btn>
                </router-link>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import rules from "@/helpers/rules";

export default {
    name: "Login",
    data() {
        return {
            rules,
            loading: false,
            valid: false,
            show1: false,
            input: {
                email: "",
                password: "",
                keep: true,
                notification: this.$store.state.notification,
            },
        };
    },
    methods: {
        login() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                this.$http
                    .post("auth", this.input)
                    .then((result) => {
                        this.$store.commit("SET_LOGIN", {
                            response: result,
                            email: this.input.email,
                            password: this.input.keep ? this.input.password : null,
                            remember: this.input.keep,
                        });

                        this.loading = false;
                        this.$emit("login");
                    })
                    .catch((error) => {
                        this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Usuário e/ou Senha incorretos."));
                        this.loading = false;
                    });
            } else {
                this.$eventHub.$emit("msgError", this.$t("Digite usuário e senha."));
            }
        },
    },
};
</script>